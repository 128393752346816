// Change page location based on selected category
// ----------------------------------------------------------------------------
const prQrCategoryDropDown = document.querySelector('#wmt-pr--widget-pr-by-category');
const prQrCompanyDropDown = document.querySelector('#wmt-pr--widget-pr-by-company');

if (prQrCategoryDropDown) {
    prQrCategoryDropDown.value = 0;
}

const prQnChangeLocation = selected => {
    if (selected.value !== '0') {
        window.location.href = selected.value;
    }
};

if (prQrCategoryDropDown) {
    prQrCategoryDropDown.onchange = () => {
        prQnChangeLocation(prQrCategoryDropDown);
    };
}

if (prQrCompanyDropDown) {
    prQrCompanyDropDown.onchange = function () {
        prQnChangeLocation(prQrCompanyDropDown);
    };
}
// ----------------------------------------------------------------------------

// Show/Hide distribution links
// ----------------------------------------------------------------------------
const distributionLinksAction = document.querySelectorAll('.wmt-pr--toggle-dl');

distributionLinksAction.forEach(item => {
    item.addEventListener('click', (e) => {
        e.preventDefault();

        const distributionTabArrow = item.parentElement.children[0].children[0].children[0];
        const distributionLinks = item.parentElement.children[1];

        if (distributionLinks.classList.contains('show')) {
            distributionTabArrow.innerHTML = '&plus;';
        } else {
            distributionTabArrow.innerHTML = '&minus;';
        }

        distributionLinks.classList.toggle('show');
        distributionLinks.classList.toggle('fadeInDown');
    });
});
// ----------------------------------------------------------------------------

// Image LightBox
// TODO: Switch to vanilla JavaScript
// ----------------------------------------------------------------------------
(function ($) {
    window.wmtPrLightbox = insertContent => {
        if ($('#wmt-pr--lightbox').size() == 0) {
            var theLightbox = $('<div id="wmt-pr--lightbox"/>');
            var theShadow = $('<div id="wmt-pr--lightbox-shadow"/>');
            $(theShadow).click(function (e) {
                wmtPrCloseLightbox();
            });
            $('body').append(theShadow);
            $('body').append(theLightbox);
        }

        // remove any previously added content

        $('#wmt-pr--lightbox').empty();

        // insert HTML content

        if (insertContent != null) {
            $('#wmt-pr--lightbox').append('<img src="' + insertContent + '">');
        }

        // move the lightbox to the current window top + 100px

        $('#wmt-pr--lightbox').css('top', $(window).scrollTop() + 100 + 'px');

        // display the lightbox

        $('#wmt-pr--lightbox').show();
        $('#wmt-pr--lightbox-shadow').show();
    };

    window.wmtPrCloseLightbox = () => {
        $('#wmt-pr--lightbox').hide();
        $('#wmt-pr--lightbox-shadow').hide();

        // remove contents of lightbox in case a video or other content is actively playing
        $('#wmt-pr--lightbox').empty();
    };

    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            wmtPrCloseLightbox();
        }
    });
})(jQuery);
// ----------------------------------------------------------------------------
